<template>
  <div class="content-body">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <h2 class="mb-0">NFT Staking Statistics</h2>
          <p>Earnable NFTC: {{ liquidity }}</p>
          <p>Total allocated power: {{ allocatedPower }}</p>
          <p>Released NFTC by contract: {{ released }}</p>
          <p>Total NFTC emission rate: {{ nftcEmissionRate }} / hour</p>

          <h2 v-if="!isAdmin">
            Log into the admin account to make see the rest of the page
          </h2>
          <template v-else>
            <h2>(YOU ARE THE ADMIN)</h2>

            <h4>Staking contract has to be NFTC token minter</h4>
            <button
              class="btn btn-success"
              type="button"
              @click="registerStakingAsNftcMinter"
            >
              Register as NFTC minter
            </button>
            <br />

            <h2 class="mb-0">Control staking contract</h2>
            Staking is active: {{ !stakingPaused }}
            <p>
              <button
                @click="stakeAdminPause"
                :disabled="stakingPaused"
                class="btn btn-danger"
              >
                Pause
              </button>
              <button
                @click="stakeAdminUnpause"
                :disabled="!stakingPaused"
                class="btn btn-success"
              >
                Unpause</button
              >&nbsp;
            </p>
            <p>
              <button @click="resetBoosts" class="btn btn-warning">
                Reset boosts
              </button>
            </p>
            <p>
              <button @click="stakeAdminAddLiquidity" class="btn btn-danger">
                Add 36M NFTC supply to staking contract (requires that staking
                is paused)
              </button>
            </p>

            <br />

            <div>
              <h2 class="mb-0">EXP Token Shop</h2>
              <h4 class="lead mx-auto my-3">
                <table class="mx-auto my-4" style="width: 80%">
                  <tr>
                    <th>1 EXP costs</th>
                  </tr>
                  <tr v-for="_token in acceptedTokens" :key="_token">
                    <td>
                      {{ expTokenPrice[_token] / 10 ** decimals[_token] }}
                      {{ tokenName[_token] }}
                    </td>
                    <td>
                      <input
                        type="number"
                        v-model="expTokenPrice[_token]"
                        size="18"
                      />
                      <button
                        class="btn btn-warning"
                        type="button"
                        @click="setNewExpPrice(_token)"
                      >
                        Set New {{ tokenName[_token] }} Price
                      </button>
                    </td>
                  </tr>
                </table>
              </h4>

              <h6>
                Note: Setting price to 0 means that the EXP token seller
                contract stops accepting the token as payment.
              </h6>
              <br />

              <h4>EXP Token Seller Contract Balances</h4>
              <p>
                {{ Math.round(expTokenSellerBchBalance / 10 ** 15) / 10 ** 3 }}
                BCH
              </p>
              <p>
                {{ Math.round(expTokenSellerNftcBalance / 10 ** 15) / 10 ** 3 }}
                NFTC
              </p>
              <p>
                {{
                  Math.round(expTokenSellerFlexUsdBalance / 10 ** 15) / 10 ** 3
                }}
                flexUSD
              </p>
              <div>
                <button
                  class="btn btn-primary"
                  type="button"
                  :disabled="expTokenSellerBchBalance <= 0"
                  @click="withdrawBchFromExpTokenSeller"
                >
                  withdraw BCH
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  :disabled="expTokenSellerNftcBalance <= 0"
                  @click="withdrawNFTCFromExpTokenSeller"
                >
                  withdraw NFTC
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  :disabled="expTokenSellerFlexUsdBalance <= 0"
                  @click="withdrawFlexUsdFromExpTokenSeller"
                >
                  withdraw flexUSD
                </button>
              </div>
              <br />

              <h2>Graveyard has to be EXP token minter</h2>
              <div>
                Graveyard is minter:
                <strong>{{ isMinter["graveyard"] }}</strong>
              </div>
              <div>
                <button
                  class="btn btn-danger"
                  type="button"
                  :disabled="!isMinter['graveyard']"
                  @click="unregisterAsExpMinter('graveyard')"
                >
                  Unregister as EXP minter
                </button>
                <button
                  class="btn btn-success"
                  type="button"
                  :disabled="isMinter['graveyard']"
                  @click="registerAsExpMinter('graveyard')"
                >
                  Register as EXP minter
                </button>
              </div>
              <br />

              <h2 class="mb-0">Register Graveyard with Staking</h2>
              <p>
                Graveyard is registered with staking contract:
                <strong>{{ registeredWithStaking["graveyard"] }}</strong>
              </p>
              <button
                @click="registerGraveyardWithStaking"
                :disabled="registeredWithStaking['graveyard']"
                class="btn btn-warning"
              >
                Register graveyard with staking contract
              </button>
              <br />
              <br />
              <br />

              <h2>EXP Token Seller has to be EXP token minter</h2>
              <div>
                TokenSeller is EXP minter:
                <strong>{{ isMinter["expSeller"] }}</strong>
              </div>
              <div>
                <button
                  class="btn btn-danger"
                  type="button"
                  :disabled="!isMinter['expSeller']"
                  @click="unregisterAsExpMinter('expSeller')"
                >
                  Unregister as EXP minter
                </button>
                <button
                  class="btn btn-success"
                  type="button"
                  :disabled="isMinter['expSeller']"
                  @click="registerAsExpMinter('expSeller')"
                >
                  Register as EXP minter
                </button>
              </div>
              <br />

              <h4>Pause and Resume EXP Token Seller</h4>
              <div>
                Token Sale active: <strong>{{ expSaleActive }}</strong>
              </div>
              <div>
                <button
                  class="btn btn-danger"
                  type="button"
                  :disabled="!expSaleActive"
                  @click="expSellerSetPaused(true)"
                >
                  Pause Sale
                </button>
                <button
                  class="btn btn-success"
                  type="button"
                  :disabled="expSaleActive"
                  @click="expSellerSetPaused(false)"
                >
                  Resume Sale
                </button>
              </div>
            </div>

            <br />
            <br />

            <h2 class="mb-0">Register Leveler</h2>
            <p>
              Leveler is registered with staking contract:
              <strong>{{ registeredWithStaking["leveler"] }}</strong>
            </p>
            <p>
              <button
                @click="registerLevelerWithStaking"
                :disabled="registeredWithStaking['leveler']"
                class="btn btn-warning"
              >
                Register leveler with staking contract
              </button>
              <button
                @click="registerLevelerWithPower"
                :disabled="levelerRegisteredWithPower"
                class="btn btn-warning"
              >
                Register leveler with power contract
              </button>
            </p>
            <br />

            <div>
              <h2 class="mb-0">NFT Power Settings</h2>
              <p>
                <span v-for="n in 5" :key="n">
                  <button
                    @click="powerSetter('pgc', (n - 1) * 2000, 2000)"
                    class="btn btn-danger"
                  >
                    Set initial power for Pixelguy {{ (n - 1) * 2000 + 1 }} -
                    {{ n * 2000 }}</button
                  >&nbsp;
                </span>
              </p>
              <p>
                <span v-for="n in 5" :key="n">
                  <button
                    @click="powerSetter('shorai', (n - 1) * 2000, 2000)"
                    class="btn btn-danger"
                  >
                    Set initial power for Shorai {{ (n - 1) * 2000 + 1 }} -
                    {{ n * 2000 }}</button
                  >&nbsp;
                </span>
              </p>
              <p>
                <span v-for="n in 12" :key="n">
                  <button
                    @click="powerSetter('kensho', (n - 1) * 1000, 1000)"
                    class="btn btn-danger"
                  >
                    Set initial power for Kensho {{ (n - 1) * 1000 + 1 }} -
                    {{ n * 1000 }}</button
                  >&nbsp;
                </span>
              </p>
              <p>
                <span v-for="n in 1" :key="n">
                  <button
                    @click="powerSetter('kenshoX', (n - 1) * 240, 240)"
                    class="btn btn-danger"
                  >
                    Set initial power for Kensho Exclusive
                    {{ (n - 1) * 240 + 1 }} - {{ n * 240 }}</button
                  >&nbsp;
                </span>
              </p>
              <p>
                <span v-for="n in 10" :key="n">
                  <button
                    @click="powerSetter('kimon', (n - 1) * 1000, 1000)"
                    class="btn btn-danger"
                  >
                    Set initial power for Kimon {{ (n - 1) * 1000 + 1 }} -
                    {{ n * 1000 }}</button
                  >&nbsp;
                </span>
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import ERC20Basic from "../../artifacts/contracts/ERC20Basic.sol/ERC20Basic.json";
import NftPower from "../../artifacts/contracts/NftPower.sol/NftPower.json";
import NftStake from "../../artifacts/contracts/NftStake.sol/NftStake.json";
// import NftStake from "../../artifacts/contracts/NftStaking.sol/NftStaking.json";
import ExpTokenSeller from "../../artifacts/contracts/ExpTokenSeller.sol/ExpTokenSeller.json";
import ExpToken from "../../artifacts/contracts/ExpToken.sol/ExpToken.json";
import Graveyard from "../../artifacts/contracts/Graveyard.sol/Graveyard.json";

// TODO Net Switching
import shoraiPower from "../bchMainnet/shorai-power.json";
import pixelguyPower from "../bchMainnet/pixelguy-power.json";
import kenshoPower from "../bchMainnet/kensho-power.json";
import kenshoXPower from "../bchMainnet/kenshoX-power.json";

import kimonPower from "../dogeMainnet/kimon-power.json";

let powerToSet = {};
powerToSet["pgc"] = pixelguyPower;
powerToSet["shorai"] = shoraiPower;
powerToSet["kensho"] = kenshoPower;
powerToSet["kenshoX"] = kenshoXPower;

powerToSet["kimon"] = kimonPower;

export default {
  name: "App",
  components: {},
  data() {
    return {
      contract: {},
      isMinter: {},
      tokenName: window.tokenName,
      decimals: window.decimals,
      ETH: window.tokenName["eth"],
      NFTC: window.NFTC,

      expSaleActive: false,
      expTokenPrice: {},
      acceptedTokens: window.acceptedTokensExpSeller,

      expTokenSellerBchBalance: 0,
      expTokenSellerNftcBalance: 0,
      expTokenSellerFlexUsdBalance: 0,

      registeredWithStaking: {},
      levelerRegisteredWithPower: false,

      isAdmin: false,

      stakingPaused: 0,
      allocatedPower: null,
      released: null,
      liquidity: null,
      earned: null,
      unallocatedEarned: null,
      nftcEmissionRate: null,
    };
  },
  methods: {
    getExpTokenPrices: async function () {
      let _tokenCAs = [];
      for (let i in this.acceptedTokens) {
        let _token = this.acceptedTokens[i];
        if (_token === "eth") {
          continue;
        }
        _tokenCAs.push(window.address[_token]);
      }

      this.contract["expSeller"]
        .getExpPriceInTokensAndEthAndPaused(_tokenCAs)
        .then((pricesE0) => {
          for (let i in this.acceptedTokens) {
            let _token = this.acceptedTokens[i];
            this.expTokenPrice[_token] = ethers.BigNumber.from(pricesE0[i]);
          }

          this.expTokenPrice["eth"] = ethers.BigNumber.from(
            pricesE0[pricesE0.length - 2]
          );

          this.expSaleActive = pricesE0[pricesE0.length - 1].eq(1)
            ? false
            : true;
        });
    },

    withdrawBchFromExpTokenSeller: async function () {
      let tx = await this.contract["expSeller"].withdrawBch();
      await tx.wait();
      window.location.reload();
    },
    withdrawNFTCFromExpTokenSeller: async function () {
      let tx = await this.contract["expSeller"].withdrawToken(
        window.address["nftc"]
      );
      await tx.wait();
      window.location.reload();
    },
    withdrawFlexUsdFromExpTokenSeller: async function () {
      let tx = await this.contract["expSeller"].withdrawToken(
        window.address["flexUsd"]
      );
      await tx.wait();
      window.location.reload();
    },

    setNewExpPrice: async function (_token) {
      if (_token === "eth") {
        let tx = await this.contract["expSeller"].setNewPriceEth(
          this.expTokenPrice.eth.toString()
        );
        await tx.wait();
      } else {
        let tx = await this.contract["expSeller"].setNewPriceToken(
          window.address[_token],
          this.expTokenPrice._token.toString()
        );
        await tx.wait();
      }
      window.location.reload();
    },

    expSellerSetPaused: async function (_newPaused) {
      let tx = await this.contract["expSeller"].setPaused(_newPaused);
      await tx.wait();
      window.location.reload();
    },

    registerAsExpMinter: async function (_contractName) {
      let tx = await this.contract["exp"].addMinter(
        window.address[_contractName]
      );
      await tx.wait();
      window.location.reload();
    },
    unregisterAsExpMinter: async function (_contractName) {
      let tx = await this.contract["exp"].removeMinter(
        window.address[_contractName]
      );
      await tx.wait();
      window.location.reload();
    },
    stakeAdminAddLiquidity: async function () {
      let tx = await this.contract["staking"].addLiquidity(
        "36000000000000000000000000"
      );
      await tx.wait();
      window.location.reload();
    },
    stakeAdminPause: async function () {
      let tx = await this.contract["staking"].pause();
      await tx.wait();
      window.location.reload();
    },
    stakeAdminUnpause: async function () {
      let tx = await this.contract["staking"].unpause();
      await tx.wait();
      window.location.reload();
    },

    resetBoosts: async function () {
      let tx = await this.contract["staking"].resetBoosts();
      await tx.wait();
      window.location.reload();
    },
    registerLevelerWithPower: async function () {
      let tx = await this.contract["power"].setLeveler(
        window.address["leveler"]
      );
      await tx.wait();
      window.location.reload();
    },
    registerLevelerWithStaking: async function () {
      let tx = await this.contract["staking"].setLevelerContract(
        window.address["leveler"]
      );
      await tx.wait();
      this.registeredWithStaking["leveler"] = true;
    },

    registerGraveyardWithStaking: async function () {
      let tx = await this.contract["staking"].setGraveyardContract(
        window.address["graveyard"]
      );
      await tx.wait();
      this.registeredWithStaking["graveyard"] = true;
    },

    powerSetter: async function (_collection, start, size) {
      let ids = [];
      for (var i = start + 1; i <= start + size; i++) {
        ids.push(i);
      }
      let tx = await this.contract["power"].bulkSet(
        window.address[_collection],
        ids,
        powerToSet[_collection].power.slice(start, start + size)
        // ,
        // {
        //   gasLimit: 25000000,
        // }
      );
      await tx.wait();
    },

    registerStakingAsNftcMinter: async function () {
      let tx = await this.contract["nftc"].setStakingContract(
        window.address["staking"]
      );
      await tx.wait();
    },
  },
  mounted: function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    this.provider
      .send("eth_requestAccounts", [])
      .then(() => {
        this.signer = this.provider.getSigner();

        for (let i in this.acceptedTokens) {
          let _token = this.acceptedTokens[i]; // VueJS makes it an object instead of an array
          if (_token === "eth") {
            continue;
          }
          this.contract[_token] = new ethers.Contract(
            window.address[_token],
            ERC20Basic.abi,
            this.provider.getSigner()
          );
        }

        this.contract["flexUsd"] = new ethers.Contract(
          window.address["flexUsd"],
          ERC20Basic.abi,
          this.provider.getSigner()
        );
        this.contract["nftc"] = new ethers.Contract(
          window.address["nftc"],
          ERC20Basic.abi,
          this.provider.getSigner()
        );
        this.contract["power"] = new ethers.Contract(
          window.address["power"],
          NftPower.abi,
          this.provider.getSigner()
        );
        this.contract["staking"] = new ethers.Contract(
          window.address["staking"],
          NftStake.abi,
          this.provider.getSigner()
        );
        this.contract["expSeller"] = new ethers.Contract(
          window.address["expSeller"],
          ExpTokenSeller.abi,
          this.provider.getSigner()
        );
        this.contract["exp"] = new ethers.Contract(
          window.address["expToken"],
          ExpToken.abi,
          this.provider.getSigner()
        );
        this.contract["graveyard"] = new ethers.Contract(
          window.address["graveyard"],
          Graveyard.abi,
          this.provider.getSigner()
        );
      })
      .then(() => {
        this.signer.getAddress().then((address) => {
          if (address === window.address["dev"]) {
            this.isAdmin = true;
          }
        });
        this.contract["staking"].earnableTokens().then((amount) => {
          this.liquidity = ethers.utils.formatEther(amount);
        });
        this.contract["staking"].allocatedPower().then((power) => {
          this.allocatedPower = power;
        });
        this.contract["staking"].released().then((amount) => {
          this.released = ethers.utils.formatEther(amount);
        });
        this.contract["staking"].getEarningPerHour().then((amount) => {
          this.nftcEmissionRate = ethers.utils.formatEther(amount);
        });
        this.contract["staking"].paused().then((isPaused) => {
          this.stakingPaused = isPaused;
        });
        this.contract["staking"]
          .isLevelerIsGraveyardContract(
            window.address["leveler"],
            window.address["graveyard"]
          )
          .then((_isLevelerIsGraveyard) => {
            console.log(_isLevelerIsGraveyard);
            this.registeredWithStaking["leveler"] = _isLevelerIsGraveyard[0];
            this.registeredWithStaking["graveyard"] = _isLevelerIsGraveyard[1];
          });
        this.contract["exp"]
          .isMinter(window.address["staking"])
          .then((_isMinter) => {
            this.isMinter["staking"] = _isMinter;
          });
        this.contract["exp"]
          .isMinter(window.address["graveyard"])
          .then((_isMinter) => {
            this.isMinter["graveyard"] = _isMinter;
          });
        this.contract["exp"]
          .isMinter(window.address["expSeller"])
          .then((_isMinter) => {
            this.isMinter["expSeller"] = _isMinter;
          });
        this.provider
          .getBalance(window.address["expSeller"])
          .then((balance) => {
            this.expTokenSellerBchBalance = balance;
          });
        this.contract["nftc"]
          .balanceOf(window.address["expSeller"])
          .then((balance) => {
            this.expTokenSellerNftcBalance = balance;
          });
        this.contract["flexUsd"]
          .balanceOf(window.address["expSeller"])
          .then((balance) => {
            this.expTokenSellerFlexUsdBalance = balance;
          });
        this.getExpTokenPrices();
      });
  },
};
</script>